import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-det',
  templateUrl: './payment-det.component.html',
  styleUrls: ['./payment-det.component.scss']
})
export class PaymentDetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
